import React from "react";

import TitleFragment from "./TitleFragment";

import '../App.css';

function AboutUs() {
    return (
        <section className="about">
            <TitleFragment title="About Us" />

            <div className="app_paragraph_two"><b>PthinkS Private Limited</b> is a premier technology company with a decade-long dedication to C++, Qt, and QML software development and OpenGL implementation. Our commitment to excellence is rooted in the core principles of innovation, ethics, and discipline, which have guided us to become a trusted partner for businesses seeking to harness the power of cutting-edge technology.</div>

            <div className="app_paragraph_two">Over the years, we have honed our expertise to deliver robust, cross-platform solutions that combine high performance with seamless user experiences. Our deep understanding of OpenGL enables us to create visually compelling and responsive applications, pushing the limits of graphical performance.</div>

            <div className="app_paragraph_two">PthinkS is recognized for its ability to tackle complex challenges, providing customized solutions that align with our clients’ specific needs. We are more than just a technology provider; we are a partner in our clients’ success, committed to delivering software that not only meets but consistently exceeds expectations.</div>

            {/* <div className="app_paragraph">PthinkS specializes in <a className="app_link" href="https://www.qt.io/">Qt</a>, QtQuick (<a className="app_link" href="https://doc.qt.io/qt-6/qmlapplications.html">QML</a>), Qt Mobility software development and is a software training organization. Our focus is always being to provide highly professional and quality services. <b>“We do what we know best. Nothing beyond”</b></div> */}

            <div className="app_sub_title">Our Philosophy</div>

            <div className="app_paragraph_two">At PthinkS, we are passionate about creating a world where technology serves as a force for good. We believe in the unwavering power of ethics, principles, and discipline, guiding every decision we make and every solution we craft.</div>

            <div className="app_paragraph_two">Our commitment to these values ensures that when you choose PthinkS, you’re choosing a partner who cares deeply about your success and integrity. We don’t just deliver solutions; we deliver peace of mind, knowing that your technology is built on a foundation of trust and excellence.</div>

            <div className="app_sub_title">Our Journey</div>

            <div className="app_paragraph_two">Since our inception, PthinkS has been at the forefront of cutting-edge technology, specializing in Qt and QML software development and OpenGL. Our journey began with a simple yet powerful idea: to create software that is not only functional but also beautiful and intuitive. This vision has guided us through every project, helping us to consistently deliver solutions that exceed our clients’ expectations.</div>

            <div className="app_paragraph_two">Over the years, we have grown from a small team of passionate developers into a full-fledged technology powerhouse. Our commitment to staying ahead of the curve has driven us to continuously innovate and expand our expertise, allowing us to tackle even the most complex challenges with confidence.</div>

            <div className="app_sub_title">Our Expertise</div>

            <div className="app_paragraph_two">PthinkS is renowned for its deep technical expertise in Qt and QML, enabling us to develop cross-platform applications that are both robust and visually appealing. Our proficiency in OpenGL further enhances our ability to deliver stunning graphics and visualizations, making us a preferred partner for businesses seeking to create immersive user experiences.</div>

            <div className="app_sub_title">Core Terminology</div>

            <div className="app_paragraph_two">At PthinkS, we believe that great software is built on a foundation of strong ethics, sound principles, and unwavering discipline. These values are at the heart of everything we do, driving us to deliver solutions that are not only innovative but also trustworthy and reliable.</div>

            <div className="app_sub_title">Looking Ahead</div>

            <div className="app_paragraph_two">As we continue to grow and evolve, our focus remains on pushing the boundaries of what’s possible in software development. We are committed to helping our clients achieve their goals by providing them with the tools and technologies they need to succeed in an increasingly competitive landscape.</div>
        </section>
    )
}

export default AboutUs;