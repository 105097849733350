import React from "react";

import mail from "./images/header/email.webp";
import phone from "./images/header/telephone.webp";

import "./styles/info.css";

function TopInfo() {
    return (
        <section className="info">
            <div className="info_div">
                <img className="info_div_img" src={mail} alt="mail" />
                &nbsp;&nbsp;&nbsp;
                <a className="info_num" href="mailto:info@pthinks.com">info@pthinks.com</a>
                &nbsp;&nbsp;&nbsp;
                <img className="info_div_img" src={phone} alt="mail" />
                &nbsp;&nbsp;
                <a className="info_num" href="tel:+918028606408">+91 80 2860 6408</a>
            </div >
        </section >
    )
}

export default TopInfo;