import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import TitleFragmentImage from "./TitleFragmentImage";
import bgVid from './images/landing/landing_bg_gif.gif';

import LandingCardsData from "./data/LandingCardsData";
import LandingServicesData from "./data/LandingServicesData";
import LandingQTImagesData from "./data/LandingQTImagesData";
import LandingTeamMembersData from "./data/LandingTeamMembersData";
import ScrollToTop from "./ScrollToTop";

import custLogos from "./images/landing/CustomeLogos.webp";
import linkedin from "./images/team/linkedin.webp";
import linkedinHover from "./images/team/linkedin-hover.webp";

import './styles/Landing.css';
import './styles/Team.css'
import '../App.css';
import LandingCarousel from "./LandingCarousel";
import MissionVission from "./MissonVission";

function LandingPage() {

    const [selectedService, setSelectedService] = useState(null);
    const location = useLocation();

    const [hovered, setHovered] = useState(null);
    const [teamHovered, setTeamHovered] = useState(null);

    const handleMouseEnter = (index) => {
        setHovered(index); // Set the hovered member index
    };

    const handleMouseLeave = () => {
        setHovered(null); // Reset when mouse leaves
    };

    const handleTeamMouseEnter = (index) => {
        setTeamHovered(index);
    }

    const handleTeamMouseLeave = () => {
        setTeamHovered(null);
    }

    useEffect(() => {
        if (location.hash === '#landing_customers') {
            const element = document.getElementById('landing_customers');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleButtonClick = (service) => {
        if (selectedService && selectedService.id === service.id) {
            setSelectedService(null);
        } else {
            setSelectedService(service);
        }
    };

    return (
        <div className="landing">
            {/* <div className="scroll-down-arrow">
                ⬇️
                <svg className={`arrows ${selectedService ? 'up' : ''}`}>
                    <path className="a1" d="M0 0 L15 16 L30 0"></path>
                    <path className="a2" d="M0 10 L15 26 L30 10"></path>
                    <path className="a3" d="M0 20 L15 36 L30 20"></path>
                </svg>
            </div> */}

            <div className="landing_bg">
                <img className="landing_bg_mercImage" src={bgVid} />
                <div className="landing_bg_services">
                    {LandingServicesData.map((service) => (
                        <div key={service.id} className="landing_bg_services_stack">
                            <svg className="arrows">
                                <path className="a1" d="M0 0 L15 16 L30 0"></path>
                                <path className="a2" d="M0 10 L15 26 L30 10"></path>
                                <path className="a3" d="M0 20 L15 36 L30 20"></path>
                            </svg>
                            <div
                                className="landing_bg_services_stack_button"
                                onMouseEnter={() => handleButtonClick(service)}
                                onMouseLeave={() => handleButtonClick(service)}
                            >
                                {service.title}
                            </div>
                            {selectedService && selectedService.id === service.id && (
                                <div className="landing_services">
                                    <div className="landing_services_div">
                                        <ul className="landing_services_points">
                                            {selectedService.points.map((point, index) => (
                                                <li key={index}>{point}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div>
                    <div className="landing_bg_header">Unleash Innovation with <span style={{ color: "#0000FF" }}>PthinkS</span></div>
                    <LandingCarousel />
                </div>
            </div>

            <div className="landing_qt_images">
                {LandingQTImagesData.map((image, index) => (
                    <img key={index} className="landing_qt_images_style" src={image.src} alt={image.alt} />
                ))}
            </div>

            <div className="landing_info_table">
                <div className="landing_info_table_info">
                    <span style={{ fontWeight: '500', fontSize: '1.5rem', color: 'darkblue' }}>PthinkS Pvt Ltd</span>, we stand out as the only company in India with a dedicated team of over 100 highly skilled Qt and QML developers. Our extensive expertise in cross-platform software development ensures that we consistently deliver top-tier projects, tailored to meet your specific needs, on time and within budget.
                    <br></br>
                    <br></br>
                    Whether you’re seeking innovative cross-platform development solutions or specialized training in Qt and QML, PthinkS Pvt Ltd is your ideal partner.
                    <br></br>
                    <br></br>
                    We offer comprehensive support and cutting-edge expertise to help you succeed in today’s competitive software landscape. By partnering with us, you gain access to unparalleled knowledge and resources, empowering your projects to reach new heights. Harness the full potential of Qt and QML with PthinkS Pvt Ltd and take your software solutions to the next level.
                </div>
                <div className="landing_info_table_line"></div>
                <div className="landing_info_table_info">
                    <ul>
                        <li>
                            <b>Timely Project Delivery</b>: Our streamlined development processes ensure efficient and timely delivery of your projects, without compromising on quality.
                        </li>
                        <br></br>
                        <li>
                            <b>Expert Code Review</b>: Our dedicated code review team focuses on optimizing your codebase, reducing unnecessary maintenance work, and enhancing overall software performance.
                        </li>
                        <br></br>
                        <li>
                            <b>Customized Qt/Qml Training Programs</b>: We design training content tailored to your specific requirements, minimizing costs and training time. This approach not only accelerates competency development but also aligns with your project goals.
                        </li>
                    </ul>
                </div>
            </div>

            <section id="landing_customers" className="landing_customers">
                <TitleFragmentImage title="We are working with" />
                <div className="landing_customers_grid">
                    <img className="landing_customers_grid_block_img" src={custLogos} alt="Customers Logos" />
                </div>
                <Link onClick={ScrollToTop} className="landing_customers_more" to='/more-customers'>More customers ...</Link>
            </section>

            <div className="app_paragraph">In addition to its focus on Qt and QML software development, The Qt Company has also expanded its offerings to include the development of software tools and technologies for the Internet of Things (IoT) and industrial automation markets. Some of the company’s key customers include companies in the automotive, aerospace, and medical device industries, as well as government agencies and research organizations.</div>

            <section className="landing_cards">
                {LandingCardsData.map((card, index) => (
                    <Link key={index} onClick={ScrollToTop} to={card.link} class="flip-card">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <img src={card.img} alt={card.alt} className="landing_cards_menu_img" />
                                <h1 className="landing_cards_menu_title">{card.title}</h1>
                            </div>
                            <div class="flip-card-back">
                                <p className="landing_cards_menu_info">{card.info}</p>
                            </div>
                        </div>
                    </Link>
                ))}
            </section>

            <MissionVission />

            <TitleFragmentImage title="Executive Team" />

            <section className="landing_teams" onMouseEnter={handleTeamMouseEnter} onMouseLeave={handleTeamMouseLeave}>
                <div className="team_members">
                    {LandingTeamMembersData.map((member, index) => (
                        <div key={index} className="team_members_individual">
                            <div className="team_members_individual_img_div">
                                <img className="team_members_individual_img" src={member.src} alt={member.alt} />
                                <a href={member.social} target="_blank">
                                    <img className="team_members_individual_logo"
                                        src={hovered === index ? linkedinHover : linkedin}
                                        alt="Linkedin logo"
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave} />
                                </a>
                            </div>
                            <div className="team_members_individual_name">{member.name}</div>
                            <div className="team_members_individual_designation">{member.designation}</div>
                            <br></br>
                            <div
                                className={`team_members_individual_description ${teamHovered ? 'expanded' : 'collapsed'}`}
                            >
                                {teamHovered
                                    ? member.description
                                    : `${member.description.substring(0, 50)}...`}
                            </div>
                        </div>
                    ))}
                </div>
                <Link onClick={ScrollToTop} className="landing_customers_more" to='/team'>Know more about the team ...</Link>
            </section>

        </div>
    )
}

export default LandingPage;