import avatarImg from "../images/team/img_avatar.webp";
import dheerendraProfile from "../images/team/dheerendra-profile.webp";
import bharathProfile from "../images/team/bharath-profile.webp";
import vijayProfile from "../images/team/vijay-profile.webp";
import gopalProfile from "../images/team/gopal-profile.webp";

const teamMembersData = [
    {
        src: dheerendraProfile,
        alt: 'Dheerendra V Purohit',
        name: 'Dheerendra V Purohit',
        designation: 'Founder & CTO',
        social: "https://www.linkedin.com/in/dheerendrap/",
        description: "Founder of PthinkS and Qt Champion, has 25+ years of expertise in C++, Qt, and QML, leading a team of 100+ engineers. A BITS Pilani alumnus and patent holder, he collaborates with top firms like Mercedes Benz, Siemens, Baxter, NXP, etc."
    },
    {
        src: gopalProfile,
        alt: 'Gopalkrishnan Lakshmi',
        name: 'Gopalkrishnan Lakshmi',
        designation: 'Director of Growth & Strategy',
        social: "https://www.linkedin.com/company/pthinks/",
        description: "With over 35+ years of experience, drives business expansion and strategic planning with a strong focus on technology solutions. Combines extensive expertise in technology and leadership to foster innovation and build strategic partnerships with global clients, ensuring sustainable growth and success."
    },
    {
        src: vijayProfile,
        alt: 'Vijay Mangalpally',
        name: 'Vijay Mangalpally',
        designation: 'Senior Architect',
        social: "https://www.linkedin.com/in/vijay-kumar-mangalpally-0215a110/",
        description: "With over 25+ years of experience, specializes in designing and developing advanced solutions in C++, Qt, and QML. With a wealth of technical expertise, leads complex projects and collaborates with global clients to deliver cutting-edge software architectures."
    },
    {
        src: bharathProfile,
        alt: 'Bharath R',
        name: 'Bharath R',
        designation: 'Manager–Learning and Development',
        social: "https://www.linkedin.com/in/withbharath/",
        description: "With over 17+ years of experience, drives strategic growth by designing and implementing tailored training programs that align with organizational goals. By leveraging innovative learning methodologies and ensuring compliance, they enhance workforce capabilities, foster leadership, and support organizational transformation."
    }
];

export default teamMembersData;