import React from 'react';
import { Link } from 'react-router-dom';

import errorImg from "./images/computer.webp";

import './styles/ErrorPage.css';

const ErrorPage = () => {
    return (
        <div className="error_page">
            <div className='error_page_error'>Error</div>

            <img className='error_page_img' src={errorImg} alt='error image' />

            <div className='error_page_not_found'>404 - Page Not Found</div>

            <div className='error_page_text'>Sorry, the page you are looking for does not exist.</div>

            <Link to="/">Go to Homepage</Link>
        </div>
    );
};

export default ErrorPage;
