import React from "react";

import FooterSocialLinksData from "./data/FooterSocialLinksData";
import FooterContactInfoData from "./data/FooterContactInfoData";
import location from "./images/footer/pin.webp";
import addressQR from "./images/footer/office-address.png";
import whatsappImg from "./images/careers/whatsapp.webp";
import kaFlag from "./images/footer/karnataka_flag.webp";
import indFlag from "./images/reach/india.webp";

import './styles/Footer.css';

function Footer() {
    return (
        <section className="footer">
            <div className="footer_qtforum">
                <div className="footer_qtforum_info">
                    Are you facing any Qt/QML technical issue? Post your question in Qt forum we will address
                </div>
                <a className="footer_qtforum_link" href="https://forum.qt.io/user/dheerendra" target="_blank">
                    <div className="footer_qtforum_btn">Qt Forum</div>
                </a>
            </div>

            <div className="footer_idea">
                <div className="footer_idea_title">Where Your <span style={{ color: "#2962FF" }}>Voice</span><br></br>Matters & <span style={{ color: "#FF8A65" }}>Ideas</span> Flow</div>
                <div className="footer_idea_info">Got <span style={{ color: "#2962FF" }}>questions</span> or <span style={{ color: "#FF8A65" }}>Ideas</span>?<br></br>
                    Let's chat!&nbsp;&nbsp;
                    <a href="https://api.whatsapp.com/send/?phone=918762331536&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
                        <img style={{ width: "3rem" }} className="footer_social_img footer_loc_img" src={whatsappImg} alt="Whatsapp Img" />
                    </a>
                </div>
            </div>

            <div className="footer_social">
                {FooterSocialLinksData.map((link, index) => (
                    <a key={index} href={link.href} target="_blank" rel="noopener noreferrer">
                        <img className="footer_social_img" src={link.src} alt={link.alt} />
                    </a>
                ))}
            </div >

            <div className="footer_main">
                <div className="footer_left">
                    <div className="footer_kannada"><span style={{ color: "#2962FF" }}>ಪಿಥಿಂಕ್ಸ್</span>&nbsp; ಪ್ರೈವೇಟ್&nbsp; ಲಿಮಿಟೆಡ್</div>
                    <div className="footer_info">
                        {FooterContactInfoData.map((info, index) => (
                            <div key={index} className="footer_info_div">
                                <img className="footer_info_img" src={info.src} alt={info.alt} />
                                <a className="footer_info_link" href={info.href}>{info.text}</a>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="footer_address">
                    <div className="footer_address_div">
                        <div className="footer_title"><span style={{ color: "#2962FF" }}>PthinkS</span> Private Limited</div>
                        <div className="footer_location">
                            <a target="_blank" href="https://www.google.com/maps/dir//638,+1Floor,+Rajeswari+Altius,+Remco+Bhel+Layout,+Ideal+Homes+Twp,+Rajarajeshwari+Nagar,+Bengaluru,+Karnataka+560098/@12.9289819,77.4318325,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae3e573c1b9acb:0xfb9b7eaf50ef5840!2m2!1d77.5142344!2d12.9289948?entry=ttu&g_ep=EgoyMDI0MTIwMS4xIKXMDSoASAFQAw%3D%3D">
                                <img className="footer_info_img footer_loc_img" src={location} alt="location" />
                            </a>
                            <div className="footer_address_info">638(P-1), 1st Floor, Rajeshwari Altius, Ideal Home,<br></br>Rajarajeshwari Nagara, Bengaluru–560098,<br></br>Karnataka&nbsp;<img className="footer_flags" src={kaFlag} ></img>, India&nbsp;<img className="footer_flags" src={indFlag} ></img></div>
                        </div>
                    </div>
                    <img className="footer_address_qr" src={addressQR} alt="Address QR" />
                </div>
            </div>

            <div className="footer_copyright">&copy; PTHINKS PRIVATE LIMITED Copyright 2024. All rights reserved.</div>
        </section >
    )
}

export default Footer;