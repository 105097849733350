import pplIcon from '../images/landing/group.webp';
import teachIcon from '../images/landing/online-counseling.webp';
import classIcon from '../images/landing/classroom.webp';

const LandingCardsData = [
    {
        title: 'Qt/QML Consulting',
        img: pplIcon,
        alt: 'group icon',
        info: 'Comprehensive Qt and QML consulting engagements for any size project.',
        link: '/qt-consulting'
    },
    {
        title: 'Qt/QML Training',
        img: teachIcon,
        alt: 'teach icon',
        info: 'Qt and QML training workshops tailored to your team\'s specific needs.',
        link: '/qt-training'
    },
    {
        title: 'Qt/QML Coaching',
        img: classIcon,
        alt: 'class icon',
        info: 'Individual or small-group mentoring, which takes a more personal approach than our training workshops.',
        link: '/qt-coaching'
    }
];

export default LandingCardsData;
