import React from "react";

import TitleFragmentImage from "./TitleFragmentImage";
import MissionData from "./data/MissionData";
import VissionData from "./data/VissionData";

import mission from "./images/landing/mission.webp";
import vission from "./images/landing/vission.webp";
import check from "./images/landing/check.webp";

import "./styles/mv.css";

function MissionVission() {
    return (
        <section className="mv">
            <TitleFragmentImage title="Mission & Vision" />

            <div className="mv_main">
                <img className="mv_main_img" src={mission} alt="mission" />
                <div className="mv_main_info">
                    <div className="mv_main_info_title">OUR MISSION</div>
                    <div className="mv_main_info_para">“Transforming challenges into seamless solutions with innovation and excellence. We are dedicated to delivering impactful results while upholding strong ethical standards. Committed to client satisfaction and sustainable success.”</div>
                    <div className="mv_main_info_divider"></div>
                    <div className="mv_main_info_points">
                        {MissionData.map((point, index) => (
                            <div key={index} className="mv_main_info_points_point">
                                <img className="mv_main_info_check" src={check} alt="check" />
                                <div className="mv_main_info_points_point_line">{point.text}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="mv_main mv_main_vision">
                <div className="mv_main_info">
                    <div className="mv_main_info_title">OUR VISION</div>
                    <div className="mv_main_info_para">“Leading in technology and setting new standards through cutting-edge innovation. We strive to inspire growth and drive progress in every sector we touch. Dedicated to ethical practices and shaping the future.”</div>
                    <div className="mv_main_info_divider"></div>
                    <div className="mv_main_info_points">
                        {VissionData.map((point, index) => (
                            <div key={index} className="mv_main_info_points_point">
                                <img className="mv_main_info_check" src={check} alt="check" />
                                <div className="mv_main_info_points_point_line">{point.text}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <img className="mv_main_img" src={vission} alt="vision" />
            </div>
        </section>
    )
}

export default MissionVission;