import avatarImg from "../images/team/img_avatar.webp";
import sreenivasProfile from "../images/team/srinivas-profile.webp";
import manoharProfile from "../images/team/Manohar-profile.webp";
import pavanProfile from "../images/team/pavan-profile.webp";
import maruthiProfile from "../images/team/Maruthi-profile.webp";

const teamMembersData = [
    {
        src: pavanProfile,
        alt: 'Pavan Badarinath',
        name: 'Pavan Badarinath',
        designation: 'Tech Lead',
        social: "https://www.linkedin.com/company/pthinks/"
    },
    {
        src: sreenivasProfile,
        alt: 'Srinivas P',
        name: 'Srinivas P',
        designation: 'Tech Lead',
        social: "https://www.linkedin.com/company/pthinks/"
    },
    {
        src: manoharProfile,
        alt: 'Manohar S',
        name: 'Manohar S',
        designation: 'Tech Lead',
        social: "https://www.linkedin.com/company/pthinks/"
    },
    {
        src: maruthiProfile,
        alt: 'Maruthi Hanumanthegowda',
        name: 'Maruthi Hanumanthegowda',
        designation: 'Tech Lead',
        social: "https://www.linkedin.com/company/pthinks/"
    }
];

export default teamMembersData;