import React, { useState } from "react";
import { Link } from "react-router-dom";

import TitleFragment from "./TitleFragment";
import ScrollToTop from "./ScrollToTop";
import TeamMembersData from "./data/TeamMembersData";

import linkedin from "./images/team/linkedin.webp";
import linkedinHover from "./images/team/linkedin-hover.webp";
import teamPic from "./images/team/PthinkS_Team_Pic.webp";

import '../App.css';
import './styles/Team.css';

function Team() {
    const [hovered, setHovered] = useState(null);

    const handleMouseEnter = (index) => {
        setHovered(index); // Set the hovered member index
    };

    const handleMouseLeave = () => {
        setHovered(null); // Reset when mouse leaves
    };

    return (
        <div className="team">
            <TitleFragment title="Team" />

            <div className="app_sub_title">Join Our Amazing Team</div>

            <div>
                <img className="team_img" src={teamPic} />
            </div>

            <div className="app_paragraph">Become part of our innovative team at PthinkS, where we specialize in C++, Qt, and QML software solutions. Join us in creating cutting-edge technologies with a commitment to excellence, ethics, and collaboration.</div>

            <div className="team_members">
                {TeamMembersData.map((member, index) => (
                    <div key={index} className="team_members_individual">
                        <div className="team_members_individual_img_div">
                            <img className="team_members_individual_img" src={member.src} alt={member.alt} />
                            <a href={member.social} target="_blank">
                                <img className="team_members_individual_logo"
                                    src={hovered === index ? linkedinHover : linkedin}
                                    alt="Linkedin logo"
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave} />
                            </a>
                        </div>
                        <div className="team_members_individual_name">{member.name}</div>
                        <div className="team_members_individual_designation">{member.designation}</div>
                    </div>
                ))}
            </div>

            <div className="team_openings">
                <Link className="team_openings_button" onClick={ScrollToTop} to='/careers'>View open positions</Link>
            </div>
        </div>
    )
}

export default Team;